<template>
  <div>
      <alter-form :customeButton="true" @handleAppend="handleAppend" ref="alterform" :watchImageVisible="false" :modifyData="data" @formSubmit="formSubmit" :formData="formData"></alter-form>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
// import loading from '@/utils/loading.json'
import jsonData from '../jsonData'
import apis from '@/services/index'
// import constant from '@/utils/constant.js'
// import Utils from '@/utils/Utils.js'
// import apis from '@/services/index'

export default {
  name: 'evses',
  components: {
    alterForm
  },
  props: ['data'],
  data () {
    return {
      modifyData: {},
      formData: jsonData.formData_evses
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    formSubmit (data) {
      const obj = JSON.parse(JSON.stringify(data))
      this.$emit('tabChange', obj, 'evses')
    },
    handleAppend (index) {
      this.$emit('handleAppend', index)
    },
    async init () {
      const tariffGroup = await apis.TariffsBillings.getTariffGroupsSimpleList()
      this.$nextTick(() => {
        this.formData[0].createDateFirstItem[3].options = tariffGroup.data.tariff_groups
      })
    }
  }
}
</script>

<style lang="scss" scope>
</style>
