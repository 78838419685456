<template>
<div class="charging--edit">
    <el-card>
      <el-tabs v-model="activeName" :before-leave="beforeLeave">
        <el-tab-pane label="General" name="general"></el-tab-pane>
        <el-tab-pane label="OCPP" name="ocpp"></el-tab-pane>
        <el-tab-pane label="EVSEs" name="evses"></el-tab-pane>
      </el-tabs>
      <keep-alive>
        <component ref="form" @handleAppend="handleAppend" :is="name[activeName]" :data="data[activeName]" @tabChange="tabChange"></component>
      </keep-alive>
    </el-card>
    <el-card>
      <div class="footer">
        <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import generalVue from './component/general.vue'
import ocppVue from './component/ocpp.vue'
import evsesVue from './component/evses.vue'
import apis from '@/services/index'
import loading from '@/utils/loading.json'
export default {
  components: { generalVue, ocppVue, evsesVue },
  data () {
    return {
      name: {
        general: generalVue,
        ocpp: ocppVue,
        evses: evsesVue
      },
      activeName: 'general',
      data: {
        general: {},
        ocpp: {},
        evses: {}
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    handleAppend (index) {
      const number = Math.floor(Math.random() * 8999) + 1000
      this.$set(this.data.evses.evses_attributes[index], 'qr_code', number.toString())
    },
    handleSubmitClick () {
      this.$store.state.app.loading = this.$loading(loading)
      this.beforeLeave()
      const params = {
        charge_point: Object.assign({}, this.data.general,  this.data.ocpp,  this.data.evses)
      }
      let api = null
      const arr = []
      if (this.$route.params.id) {
        api = apis.AssetApi.getChargePointEdit
        arr.push(this.$route.params.id)
      } else {
        api = apis.AssetApi.getChargePointAdd
      }
      arr.push(params)
      api(...arr).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess'),
            onClose: () => { this.handleCancelClick() }
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.go(-1)
    },
    tabChange (data, name) {
      this.data[name] = data
    },
    beforeLeave () {
      this.$refs.form.$refs.alterform.handleSubmitClick()
    },
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      if (this.$route.params.id) {
        const data = await apis.AssetApi.getChargePointPreview(this.$route.params.id)
        this.data.evses = {
          evses_attributes: data.data.charge_point.evses_attributes
        }
        this.data.general = {
          name: data.data.charge_point.name,
          location_id: data.data.charge_point.location_id,
          enabled: data.data.charge_point.enabled,
          charge_point_type: data.data.charge_point.charge_point_type
        }
        this.data.ocpp = {
          charge_point_id: data.data.charge_point.charge_point_id,
          serial_number: data.data.charge_point.serial_number,
          managed_by_operator: data.data.charge_point.managed_by_operator,
          ocpp_protocol: data.data.charge_point.ocpp_protocol,
          start_stop_from_app: data.data.charge_point.start_stop_from_app,
          send_meter_update: data.data.charge_point.send_meter_update,
          stop_unplugged: data.data.charge_point.stop_unplugged,
          plug_without_authorization: data.data.charge_point.plug_without_authorization
        }
      }
      this.$store.state.app.loading.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.charging--edit {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
