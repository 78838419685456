<template>
  <div>
      <alter-form :customeButton="true" ref="alterform" :watchImageVisible="false" :modifyData="data" @formSubmit="formSubmit" :formData="formData"></alter-form>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
import apis from '@/services/index'
import jsonData from '../jsonData'

export default {
  name: 'general',
  components: {
    alterForm
  },
  props: ['data'],
  data () {
    return {
      modifyData: {},
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    async init () {
      const location =  await apis.AssetApi.getLocationSimpleList()
      this.formData[3].options = location.data.locations
    },
    formSubmit (data) {
      const obj = JSON.parse(JSON.stringify(data))
      this.$emit('tabChange', obj, 'general')
    }
  }
}
</script>

<style lang="scss" scope>
</style>
